import React, { useState, useEffect } from "react";
import Img from "gatsby-image";
import { graphql, Link } from "gatsby";
import classnames from "classnames";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Slice from "../components/slice";
import RichText from "../components/richtext";

import iconDown from "../images/icon-down.svg";
import iconUp from "../images/icon-up.svg";
import iconLeft from "../images/icon-left.svg";
import iconRight from "../images/icon-right.svg";

import projectStyles from "../css/project.module.css";

const PaginationMobileLink = ({ url, text, className, previous, next }) => (
  <Link
    to={url}
    className={classnames("opacity-30 hover:opacity-100 flex", {
      [className]: className
    })}
  >
    {previous && <img src={iconLeft} alt="back arrow" className="pr-2" />}
    {text}
    {next && <img src={iconRight} alt="forward arrow" className="pl-2" />}
  </Link>
);

const PaginationLink = ({ url, text, previous, next }) => {
  const rotation = !!previous ? 270 : 90;
  return (
    <div
      className={classnames("hidden lg:block text-center fixed top-1/2", {
        "left-70": previous,
        "right-75 md:right-115 lg:right-140": next
      })}
      style={{
        transform: `rotate(${rotation}deg)`
      }}
    >
      <Link
        to={url}
        className={classnames(
          "opacity-30 hover:opacity-100 text-dark md:text-lg"
        )}
      >
        {text}
      </Link>
    </div>
  );
};

const ProjectTemplate = ({ data, pageContext }) => {
  const [showFull, toggleShowFull] = useState(false);
  const {
    meta_title,
    meta_description,
    project_title,
    project_image,
    project_excerpt,
    video_embed_iframe_link,
    body
  } = data.prismicProject.data;
  const isVideo = !!video_embed_iframe_link;
  const isMainImage = !!project_image.fluid;
  const isPortrait =
    isMainImage &&
    project_image.dimensions.height > project_image.dimensions.width;

  return (
    <Layout>
      <SEO title={meta_title} description={meta_description} />
      <div className="mb-10">
        {pageContext.previous && (
          <PaginationLink
            url={`/${pageContext.previous}`}
            text="Previous Project"
            previous
          />
        )}
        <div>
          <div className="w-full mt-16">
            <div className={projectStyles.column}>
              {isVideo && (
                <iframe
                  className="w-full max-w-5xl mb-6 h-33vh sm:h-50vh md:h-90vh"
                  src={`${video_embed_iframe_link}?autoplay=true&title=false&byline=false&portrait=false&color=f6f4ef`}
                ></iframe>
              )}
              <div
                className={classnames({
                  "md:flex md:flex-row-reverse md:items-center ": isPortrait
                })}
              >
                {isMainImage && (
                  <Img
                    fluid={project_image.fluid}
                    alt={project_image.alt}
                    className={classnames("w-full flex-shrink-0", {
                      "md:w-1/2 md:ml-10": isPortrait,
                      "mb-6": !isPortrait
                    })}
                  />
                )}
                <div>
                  <h1 className="text-4xl mb-4 leading-tight">
                    {project_title.text}
                  </h1>
                  <RichText html={project_excerpt.html} />
                  <button
                    className="text-dark opacity-30 hover:opacity-100 cursor-pointer focus:outline-none z-10"
                    onClick={() => toggleShowFull(!showFull)}
                  >
                    Read More{" "}
                    {showFull ? (
                      <img src={iconUp} alt="icon up" className="inline" />
                    ) : (
                      <img src={iconDown} alt="icon down" className="inline" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className={classnames("mt-10", { hidden: !showFull })}>
              {body.map((slice, index) => (
                <Slice {...slice} key={index} />
              ))}
            </div>
          </div>
          {pageContext.next && (
            <PaginationLink
              url={`/${pageContext.next}`}
              text="Next Project"
              next
            />
          )}
          <div className="lg:hidden flex justify-center my-4">
            {pageContext.previous && (
              <PaginationMobileLink
                url={pageContext.previous}
                text="Previous"
                className={pageContext.next && "pr-4"}
                previous
              />
            )}
            {pageContext.next && (
              <PaginationMobileLink
                url={pageContext.next}
                text="Next"
                className={pageContext.previous && "pl-4"}
                next
              />
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ProjectByUid($uid: String!) {
    prismicProject(uid: { eq: $uid }) {
      data {
        meta_title
        meta_description
        project_title {
          text
        }
        project_excerpt {
          html
        }
        project_image {
          fluid(maxWidth: 1200) {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
          dimensions {
            width
            height
          }
          alt
        }
        video_embed_iframe_link
        body {
          ... on PrismicProjectBodyRichtext {
            slice_type
            primary {
              richtext {
                html
              }
            }
          }
          ... on PrismicProjectBodyImage {
            slice_type
            primary {
              width
              image {
                fluid(maxWidth: 1440) {
                  base64
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ProjectTemplate;
