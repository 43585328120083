import React from "react";
import Img from "gatsby-image";
import classnames from "classnames";
import RichText from "./richtext";

import projectStyles from "../css/project.module.css";

const Slice = ({ slice_type, primary, items }) => (
  <>
    {slice_type === "richtext" && (
      <RichText html={primary.richtext.html} className={projectStyles.column} />
    )}
    {slice_type === "image" && <ProjectImage {...primary} />}
  </>
);

const ProjectImage = ({ width, image }) => {
  return (
    <div
      className={classnames({
        [projectStyles.column]: width === "Column"
      })}
    >
      <Img
        fluid={image.fluid}
        className={classnames("mb-6", {
          "mx-auto mb-6 max-w-5xl": width === "Large"
        })}
      />
    </div>
  );
};

export default Slice;
